
import filters from "~/mixins/filters";
import cardClick from "~/mixins/cardClick";
import { mapGetters, mapMutations, mapActions } from "vuex";
import bodyClasses from "@/mixins/bodyClasses";
import formValidation from "@/mixins/formValidation";
import settings from "../../mixins/settings";
import metrika from "../../mixins/metrika";

export default {
  mixins: [filters, cardClick, bodyClasses, formValidation, metrika],
  data() {
    return {
      isFormVisible: false,
      formData: {
        fio: '',
        phone: ''
      }
    };
  },
  mounted() {
    this.$refs.roz_container.addEventListener('contextmenu', event => event.preventDefault());
  },
  methods: {
    onFocus() {
      this.setFocusShowFixes(false)
    },
    onFocusOut() {
      setTimeout(() => {
        this.setFocusShowFixes(true)
      }, 1000)
    },
    checkForm() {
      if (this.form.name.value.length < 2) {
        this.form.name.valid = false
        return false
      }
      if (!this.form.phone.valid) {
        this.form.phone.valid = false
        return false
      }
      if (!this.form.agree) {
        this.error = 'agree'
        return false
      }
      if (!this.form.agreeRf) {
        this.error = 'agree'
        return false
      }
      return true;
    },
    async submitForm() {
      if (this.checkForm()) {
        let formData = {
          chosen_car: {},
          type: 'callback',
          client_name: this.form.name.value,
          client_phone: this.form.phone.value,
        }

        if (this.time)
          formData.comment = `Перезвонить ${this.time}`
        // utm
        if (localStorage.utm_source) {
          formData.utm_source = localStorage.utm_source
        }
        if (localStorage.utm_medium) {
          formData.utm_medium = localStorage.utm_medium
        }
        if (localStorage.utm_campaign) {
          formData.utm_campaign = localStorage.utm_campaign
        }
        if (localStorage.utm_term) {
          formData.utm_term = localStorage.utm_term
        }
        if (localStorage.utm_content) {
          formData.utm_content = localStorage.utm_content
        }
        // if (this.offer.dealerModal) {
        //   formData.comment = this.offer.title
        // } else {
        //   formData.external_id = this.offer.external_id
        // }
        // await this.setModalCount(1)
        await this.sendForm(formData)
        await this.closeModal()

      }
    },
    windowScroll() {
      if (this.once) {
        this.once = false
        setTimeout(() => {
          this.isActive = true
        }, 4000)

        // setTimeout(()=>{
        //    this.stateMessageCallBack = true
        // },16000)
        // setTimeout(()=>{
        //    this.stateMessageCallBack = false
        // },22000)
      }
      else {
        return false
      }
    },
    ...mapActions({
      openModal: 'modal/modal-main/openModal',
      sendForm: 'form/form/sendForm',
      closeModal: 'modal/modal-main/closeModal',

    }),
    ...mapMutations({
      setFocusShowFixes: 'car/car/SET_FOCUS_SHOW_FIXED'

    }),
    callback() {
      // let payload = {
      //   modal_data: {},
      //   modal_component: 'modal-callback',
      //   modal_title: 'Обратный звонок',
      //   modal_sub_title: ''
      // }
      // this.openModal(payload)
      this.localModal = true
    },
  },

}
